<mad-header #header class="search-header" madTypography="header-3">
  <mad-body class="inner">
    <a href="javascript:void(0)" class="text-wrapper" (click)="onOpenClick()">
      <mad-icon class="search-icon" icon="fa-search"></mad-icon>
      <mad-spring [visible]="!visible" direction="horizontal">
        Go&nbsp;to&nbsp;
      </mad-spring>
      <span class="search-title">
        Search
      </span>
    </a>
  </mad-body>
</mad-header>

<div class="search-content">
  <div class="search-side" >
    <div class="menu scrollable" >
      <mad-button-group class="tabs" type="tab" direction="vertical" [(ngModel)]="selectedTab" [showTabMarker]="visible" >
        <mad-button class="tab search-full-directory-tab" type="tab" (click)="onTabClick()">
          <span class="tab-text" madTypography="header-3">Full&nbsp;directory</span>
        </mad-button>
      <mad-button class="tab search-test-mix-tab" type="tab" (click)="onTabClick()">
        <span class="tab-text" madTypography="header-3">My&nbsp;test&nbsp;mix</span>
      </mad-button>
      <mad-button class="tab search-test-match-tab" type="tab" (click)="onTabClick()">
        <span class="tab-text" madTypography="header-3">IDEXX&nbsp;test&nbsp;match</span>
      </mad-button>
      </mad-button-group>
      <mad-spring class="tab-spring search-categories" [visible]="selectedTab[0] === FULL_DIRECTORY && visible && myCategories && myCategories.length" direction="vertical">
        <ng-container *ngTemplateOutlet="categories"></ng-container>
      </mad-spring>
    </div>
  </div>

  <div class="search-content-main">
    <mad-infinite-list #searchResultList class="search-result-list scrollable" [assumedHeight]="158" [pager]="pager" [template]="resultTemplate">
      <div class="search-filter">
        <div class="inner">
            <div class="title" [class.visible]="selectedTab[0] === FULL_DIRECTORY" madTypography="header-3">
              Search&nbsp;full&nbsp;IDEXX&nbsp;directory
            </div>
            <div class="title" [class.visible]="selectedTab[0] === TEST_MIX" madTypography="header-3">
              Search&nbsp;my&nbsp;test&nbsp;mix
            </div>
            <div class="title" [class.visible]="selectedTab[0] === TEST_MATCH" madTypography="header-3">
              IDEXX&nbsp;test&nbsp;match
            </div>
        </div>
        <ng-container *ngIf="selectedTab[0] !== TEST_MATCH">
          <mad-input type="search" class="search" [loading]="isSearching" [(ngModel)]="searchValue" (ngModelChange)="onSearchValueChange()"></mad-input>
          <div class="bottom">
            <div class="bottom-pills">
              <mad-spring *ngFor="let pill of pills" class="pill-spring"  [visible]="pill.selected" (transitionend)="onPillRemoveTransitionEnd(pill)" >
                <mad-pill class="pill" [selectable]="true" [removable]="true" (action)="removePill(pill)">
                  {{pill.description}}
                  <mad-icon class="pill-remove-icon"  right icon="fa-times" (click)="removePill(pill)"></mad-icon>
                </mad-pill>
              </mad-spring>
              <mad-spring [visible]="selectedTab[0] === TEST_MIX && checkedTestmix && !isNoTestmix" direction="vertical" >
                <div class="search-result column-header">
                  <div class="lims-id h-col"></div>
                  <div class="qty h-col">
                    <mad-link (click)="changeSortDirection()">
                      Runs/12-mo.
                      <mad-spring [visible]="sortDir !== NONE">
                        <mad-icon right icon="fa-chevron-up" altIcon="fa-chevron-down" [showAlt]="sortDir === DESCENDING"></mad-icon>
                      </mad-spring>
                    </mad-link>
                  </div>
                  <div class="list h-col">
                    Price
                  </div>
                  <div class="net h-col">
                    Special Price
                  </div>
                  <div class="add-link"></div>
                </div>
              </mad-spring>
            </div>
          </div>
          <mad-spring direction="vertical" [visible]="isNoTestmix && selectedTab[0] === TEST_MIX">
            <div class="search-testmix-no-history">
              No order history in last 12 months
            </div>
          </mad-spring>
        </ng-container>
        <ng-container *ngIf="selectedTab[0] === TEST_MATCH" >
          <div style="display:flex;margin-top:3.5em;margin-bottom:0.5em;height:40px;flex-direction:row;">
            <mad-input class="match-code" style="max-width:300px;flex:1" [(ngModel)]="matchCode">
              <mad-label style="top:-0.5em;">* Test code</mad-label>
            </mad-input>
            <mad-input class="match-price" placeholder="$" style="max-width:300px;margin-left:2em;flex:1" [formatter]="{type: 'currency'}" [(ngModel)]="matchPrice">
              <mad-label style="top:-0.5em;">Price</mad-label>
            </mad-input>
            <mad-input class="runs-per-month" style="max-width:300px;margin-left:2em;flex:1" [(ngModel)]="runsPerMonth">
              <mad-label style="top:-0.5em;">Estimated runs per month</mad-label>
            </mad-input>
          </div>
          <div style="display:flex;margin-top:3.5em;margin-bottom:0.5em;height:40px;flex-direction:row;">
            <mad-input class="test-panel-description"  style="max-width:964px;flex:1" [(ngModel)]="testPanelDescription">
              <mad-label style="top:-0.5em;">* Test/Panel Description</mad-label>
            </mad-input>
          </div>
          <div style="display:flex;margin-top:1.5em;margin-bottom:0.5em;flex-direction:row;justify-content: flex-end;max-width:964px;">
            <mad-button type="primary" [disabled]="!matchCode" (click)="onMatchClick()" style="margin-right: 0;">
              <div style="display:inline-flex;align-items:center;">
                <mad-spring [visible]="isLoadingMatch" direction="horizontal">
                  <mad-icon icon="fa-spin fa-spinner"></mad-icon>
                </mad-spring>
                <mad-spring [visible]="!isLoadingMatch" direction="horizontal">
                  Go
                </mad-spring>
              </div>
            </mad-button>
          </div>
          <div style="border-bottom: solid 1px rgba(0, 0, 0, 0.1); padding-bottom: 10px;">
            <label mat-button style="cursor: pointer; color: rgb(0, 167, 181); display: flex; width: 244px;">
              <input
                type="file"
                accept=".csv"
                #fileInput
                (change)="uploadCompetitiveTestList(fileInput)"
                hidden
              />
              <ng-container *ngIf="isBulkUploading">
                <mad-spring direction="horizontal">
                  <mad-icon icon="fa-spin fa-spinner"></mad-icon>
                </mad-spring>
              </ng-container>
              <ng-container *ngIf="!isBulkUploading">
                <img src="assets/images/upload.png" style="width: 20px; height: 20px; margin-right: 10px;"/>
              </ng-container>
              Upload Competitive Test List
            </label>
          </div>
          <ng-containter  *ngIf="bulkUpload">
            <div style="display: flex; justify-content: flex-end; padding: 4px 0; gap: 10px;">
              <div style="padding-top: 8px;">
                <mad-popover class="white opaque" #popover position="left-center" >
                </mad-popover>
                <mad-link
                  class="remove hide-on-tablet large-remove"
                  [madPopover]="{popover: popover, showOnClick: true, hideOnBlur: true, type: 'pointer', content: removeConfirm}"
                >
                  Clear all
                </mad-link>
                <ng-template #removeConfirm>
                  <div style="padding:1em;display:flex;flex-direction:row;align-items:center;">
                    <div style="margin-right:2em;font-size:16px;font-weight:bold;">
                      Are you sure?
                    </div>
                    <mad-button type="secondary" style="font-size:15px" (click)="confirmClear();this.popover.hide();">Yes</mad-button>
                    <mad-button type="cancel" style="font-size:15px" (click)="popover.hide()">cancel</mad-button>
                  </div>
                </ng-template>
              </div>
              <mad-button type="secondary" (click)="handleAddAllMatches()" style="margin: 8px 0 0; color: rgb(0, 167, 181);">ADD ALL MATCHES</mad-button>
            </div>
            <div style="display: flex; border-bottom: 2px solid black; position: relative;">
              <div style="flex: 2; background-color: #989c9c; color: white; padding: 10px 2px;" id="competitor-column">
                <span style="padding: 10px;">
                  Query Item
                </span>
              </div>
              <div style="flex: 3; display: flex; align-items: center; color: black;">
                <span style="margin-left: 30px;">
                  IDEXX match
                </span>
              </div>
            </div>
          </ng-containter>
        </ng-container>
      </div>
    </mad-infinite-list>
  <div #infoBottomMark style="position:absolute;bottom:0;left:0;width:0;right:0;"></div>
</div>

<ng-template #turnAroundTimeInfo let-data="data" let-selected="selected">
  <div class="tatTooltip">Beginning on {{ data.new_tat_start_date }}, the turnaround time on this test will be updated to: <b>{{ data.new_tat }}</b>
  </div>
</ng-template>

<mad-popover #tatBubble class="opaque white" constrainTo=".account-content"  [auto]="true" position="top-left" >
</mad-popover>

<ng-template #resultTemplate let-index="index" let-height="height" let-data="data">
  <div
    #resultEl
    *ngIf="pager.results[index]?.data; let result"
    class="search-result"
    [style.padding-bottom.px]="bulkUpload ? 0 : 32"
    style="padding-left: 0; padding-right: 0;"
  >
    <div style="display: flex;" [ngClass]="{ 'archived': isArchived(result) }">
      <div
        style="flex: 2; padding: 2em 1em 2em 0; margin-left: 1em; border-right: 2px solid black; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        *ngIf="bulkUpload && selectedTab[0] === TEST_MATCH">
        <div style="display: flex;padding:2px 0">
          <div
            style="font-weight: 700; font-size: 18px; line-height: 24px; padding-left: 8px;"
            madTypography="header-2"
            [ngStyle]="{ 'color': false ? 'rgb(30, 31, 31)' : 'rgb(0, 167, 181)' }"
          >
            {{result.match_code}}
          </div>
          <div style="margin-left: 20px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-weight: 400; font-size: 15px; line-height: 24px">
            {{result.match_description}}
          </div>
        </div>
      </div>

      <div style="flex: 3; flex-basis: 0; min-width: 0; padding: 2em 0 2em 1em; margin-right: 1em;" *ngIf="!result.lims_code">
        <span style="margin-left: 100px; font-size: 18px; line-height: 18px; color: red; margin-top:6px; display: block;"> <!-- *ngIf="data.match" -->
          No IDEXX match
        </span>
      </div>

      <div style="flex: 3; flex-basis: 0; min-width: 0; padding: 0 0 2em 1em; margin-right: 1em; overflow: hidden;" *ngIf="result.lims_code">  <!-- *ngIf="!data.match" -->
        <div class="top" style="display: flex;">
          <mad-link #infoLink *ngIf="result.contains_info" class="info-link" linkClass="navigation-ignored" [madPopover]="{popover: infoBubble, target: isInfoFullScreen(infoLink) ? infoBottomMark : infoLink, type: isInfoFullScreen(infoLink) ? 'bubble' : 'pointer', showOnFocus: true, hideOnBlur: true, content: infoBubbleContent, context: {data: result, selected: [0]}}" [round]="true" tabindex="-1">
            <mad-icon icon="fa-info"></mad-icon>
          </mad-link>
          <mad-pill class="lims-id-pill" [selectable]="selectedTab[0] !== TEST_MATCH" (click)="selectedTab[0] !== TEST_MATCH ? addParentPill(result) : null">
            {{result.lims_code}}
          </mad-pill>

          <div
            *ngIf="selectedTab[0] !== TEST_MIX"
            style="max-width: 100%;flex-shrink: 1;margin-left: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-weight: 400; font-size: 15px; line-height: 24px;"
          >
            {{result.lims_name || result.lims_description}}
          </div>

          <div *ngIf="selectedTab[0] === TEST_MIX" style="margin: 0 0 0 auto; display: flex;">
            <div class="qty" style="padding-top: 0; margin-right: 70px;">{{result.qty}}</div>
            <div class="list" style="padding-top: 0; margin-right: 100px;">{{result.list | currency}}</div>
            <div class="net" style="padding-top: 0; margin-right: 60px;">{{result.net | currency}}</div>
          </div>

          <div
            class="add-link"
            style="display: flex; flex-direction: row; align-items: center;"
            [style.margin-right.px]="isArchived(result) ? 40 : 0"
            [style.margin-left]="selectedTab[0] !== TEST_MIX ? 'auto' : 'inherit'"
          >
            <mad-link
              [disabled]="containsCode(result.lims_code)"
              (click)="onResultAddClick(result, resultEl)"
              style="display: inline-flex; margin-right: 20px"
            >
              <div
                class="add-link-inner"
                [class.added]="containsCode(result.lims_code)"
              >
                <mad-spring [visible]="containsCode(result.lims_code)" direction="horizontal">
                  <mad-icon class="add-link-check" icon="fa-check">
                  </mad-icon>
                </mad-spring>
                  ADD
                <mad-spring [visible]="containsCode(result.lims_code)" direction="horizontal" >
                  ED
                </mad-spring>
              </div>
            </mad-link>

            <mad-link style="display: inline-flex;" *ngIf="selectedTab[0] === TEST_MATCH && !containsCode(result.lims_code)">
              <div class="add-link-inner" [class.added]="containsCode(result.lims_code)">
                <ng-container *ngIf="isArchived(result)">
                  <span (click)="toggleArchiveOff(result)">RESTORE</span>
                </ng-container>
                <ng-container *ngIf="!isArchived(result)">
                  <img 
                    src="assets/images/archive.png" 
                    style="width: 20px; height: 20px; margin-right: 10px;"
                    (click)="toggleArchiveOn(result)"
                  />
                </ng-container>
              </div>
            </mad-link>
          </div>
        </div>
        <div
          *ngIf="selectedTab[0] === TEST_MIX"
          style="margin-left: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-weight: 400; font-size: 15px; line-height: 24px;"
        >
          {{result?.lims_name}}
        </div>
        <div class="matches">
          <mad-pill class="match-pill" *ngFor="let component of result.matches">{{component.lims_name || component.lims_code}}</mad-pill>
        </div>
        <div class="components">
          <mad-pill class="component-pill" *ngFor="let component of result.components" [selectable]="true" (click)="addComponentPill(component)">{{component.lims_name || component.lims_code}}</mad-pill>
        </div>
        <div class="tat-section" *ngIf="result.turnaround_time">
          <div *ngIf="result.new_tat; else currentTat" class="turn-around-time">
            <mad-overlay
              messageAlign="horizontal"
              overlayStyle="normal">
            </mad-overlay>
            <mad-link
              linkClass="navigation-ignored"
              [madPopover]="{popover: tatBubble, target: infoLink, type: 'pointer', showOnFocus: true, hideOnBlur: true, content: turnAroundTimeInfo, context: {data: result}}"
              [round]="true"
            >
              <span class="clock-container"><mad-icon icon="time-clock"></mad-icon></span>
              <span class="turn-around-time-text">{{result.turnaround_time}} *</span>
            </mad-link>
          </div>
          <ng-template #currentTat>
            <div class="turn-around-time">
              <span class="current-tat clock-container"><mad-icon icon="time-clock"></mad-icon></span>
              <span class="turn-around-time-text current-tat">{{result.turnaround_time}}</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="search-result-placeholder" *ngIf="!pager.results[index]?.data" [style.height]="height+'px'">
    <a class="link top" href="#"></a>
    <a class="link bottom" href="#"></a>
  </div>
</ng-template>

<ng-template #categories>
  <div class="title">
    CATEGORIES
  </div>
  <div *ngFor="let category of myCategories;trackBy:trackByCategory" class="category" >
    <div class="category-inner">
      <mad-link class="category-expand" (click)="category.visible = !category.visible">
        <mad-icon icon="fa-plus" altIcon="fa-minus" [showAlt]="category.visible" ></mad-icon>
      </mad-link>
    </div>
    <mad-pill class="category-pill" [class.selected]="isSelected(category)" [class.inactive]="!category.active" [selectable]="category.active && !isSelected(category)" (click)="toggleCategory(category)">{{category.description}}</mad-pill>
    <mad-spring class="category-spring" [visible]="category.visible" direction="vertical">
      <div *ngFor="let child of category.children;trackBy:trackByCategory">
        <mad-pill class="category-child-pill" [class.selected]="isSelected(child)" [class.inactive]="!child.active" [selectable]="category.visible && !isSelected(child)" (click)="toggleCategory(child)">{{child.description || child.lims_code}}</mad-pill>
      </div>
    </mad-spring>
  </div>
</ng-template>

<mad-popover #infoBubble class="opaque white" constrainTo=".account-content"  [auto]="true" position="right-center" >
</mad-popover>

<ng-template #infoBubbleContent let-data="data" let-selected="selected">
  <mad-header style="height:0;min-height:0;padding:0;">
  <mad-link right (click)="infoBubble.hide()" style="z-index:2;position:absolute;right:1.5em;top:2em;">
    <mad-icon class="remove-icon" style="color:#00a7b5;" icon="fa-times" ></mad-icon>
  </mad-link>
  </mad-header>
  <div style="display:flex;flex-direction:column;padding-left:1em;padding-right:1em;padding-top:0;padding-bottom:0" [style.max-height]="getMaxInfoHeight()" [style.width]="getInfoWidth(infoBubble.target)">
    <div class="info-content" style="display:flex;flex-direction:row;flex-wrap:wrap;">
      <div class="info-left-title" madTypography="header-3" style="background:white;width:100%;padding-left:0.5em;z-index:1;position:relative;top:0em;padding-top:0.75em;padding-bottom:0.5em;">
        Component&nbsp;descriptions
        <div madTypography="tertiary" style="color:#a7a7a7">Select a test component</div>
      </div>
      <div class="info-left" style="width:400px;display:flex;flex-direction:column;min-width:300px;flex:300px;max-width:400px;padding-right:3px;overflow-y:auto;overflow-x:hidden;">
        <mad-button-group class="info-tabs scrollable" style="white-space:nowrap;box-shadow:inset -1px 0px 0px 0px #eee;max-width:100%;min-width:100%;z-index:0;overflow:hidden;margin-top:1em;margin-left:0.1em;overflow-y:auto;" type="tab" direction="vertical" [showTabMarker]="true" [ngModel]="selected" (ngModelChange)="infoIndex=$event">
          <mad-button *ngFor="let child of data.children; let i = index" [disabled]="!child.info_description" [attr.data-index]="i" [ngClass]="{'selected' : infoIndex === i}" class="info-tab" type="tab" style="background-color:white;font-size:15px;font-weight:300;">
            {{child.lims_name || child.lims_code}}
          </mad-button>
        </mad-button-group>
      </div>
      <div class="info-right" style="padding-left:1em;min-width:400px;padding-bottom:2em;flex:1;flex-direction:column;justify-content:center;padding:1em;border-left:1px solid #cccfd1;">
        <div madTypography="header-3" style="margin-bottom:1em;font-size:13px;color:#1E1F1F;font-weight:bold;text-align:start;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
          {{data.children[infoIndex]?.lims_name || data.children[infoIndex]?.lims_code}}
        </div>
        <div *ngIf="data.children[infoIndex]?.info_description" style="color:#1E1F1F;font-weight:300;font-size:13px;">
          {{data.children[infoIndex]?.info_description}}
        </div>
        <div *ngIf="!data.children[infoIndex]?.info_description" style="font-size:13px;color:#cccfd1">
          No description available
        </div>
      </div>
      <div style="z-index:1;background:white;position:relative;bottom:0em;padding-bottom:1em;padding-top:0.5em;width:100%;padding-left:0.5em;" [style.visibility]="data.info_includes ? 'visible': 'hidden'">
        <div style="font-size:13px;"><strong>Included IDEXX testing (at no additional charge):</strong> {{data.info_includes || ''}}</div>
      </div>
    </div>
  </div>
</ng-template>
