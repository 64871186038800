  <div *ngIf="data.isExpanded" class="advanced-pricing-inner" [formGroup]="data.form">
    <mad-link class="advanced-pricing-close" (click)="onClose()" style="font-size:24px;float:right;margin-right:1em;">
      <mad-icon class="advanced-pricing-close-icon" icon="fa-chevron-up" ></mad-icon>
    </mad-link>
    <div class="advanced-pricing-content" style="padding-left:1em;">
      <div class="advanced-pricing-detail-text" madTypography="header-3" style="font-size:20px;border-bottom:solid 1px #aaa;margin-right:4em;margin-top: 0.5em;padding-bottom:0.25em;">
        Panel detail
      </div>
      <div class="advanced-pricing-upper" style="display:flex;padding-top:1em;flex-wrap:wrap;max-width:1600px;margin-top:1.5em;">
        <!--<div class="advanced-pricing-info" >-->
        <div class="advanced-pricing-info-inner" style="display:flex;margin-bottom: 2em;">
          <div class="advanced-pricing-field" style="margin-right:2em;">
            <div class="advanced-pricing-title" style="margin-bottom:1em;font-weight:300">Floor price</div>
            <div class="advanced-pricing-info-value" style="font-weight:bold">{{data?.floor | currency}}</div>
          </div>
          <div class="advanced-pricing-field" style="margin-right:2em;">
            <div class="advanced-pricing-title" style="margin-bottom:1em;font-weight:300">Recommended price</div>
            <div class="advanced-pricing-info-value" style="font-weight:bold">{{data?.recommended | currency}}</div>
          </div>
          <div class="advanced-pricing-field" style="margin-right:2em;">
            <div class="advanced-pricing-title" style="margin-bottom:1em;font-weight:300">Current price</div>
            <div class="advanced-pricing-info-value" style="font-weight:bold">{{data?.current | currency}}</div>
          </div>
          <div class="advanced-pricing-field" style="margin-right:2em;">
            <div class="advanced-pricing-title" style="margin-bottom:1em;font-weight:300">List price</div>
            <div class="advanced-pricing-info-value" style="font-weight:bold">{{data?.list | currency}}</div>
          </div>
        </div>
        <!--</div>-->
        <!--<div class="advanced-pricing-runs" >-->
        <div class="advanced-pricing-runs-inner" style="display:flex;background:#fefceb;padding-left:2em;margin-top:-0.5em;padding-top:0.5em;padding-bottom:0.5em;padding-right:2em;">
          <div class="advanced-pricing-field" style="margin-right:2em;">
            <div class="advanced-pricing-title" style="margin-bottom:1em;font-weight:300">Average Runs (monthly)</div>
            <div class="advanced-pricing-runs-value" style="font-weight:bold;">{{data?.qty || 0}}</div>
          </div>
          <div class="advanced-pricing-field">
            <div class="advanced-pricing-title" style="margin-bottom:0.5em;font-weight:300">Estimated Runs (monthly)</div>
            <div class="advanced-pricing-runs-value">
              <mad-input placeholder="{{data?.qty || 0}}" [formatter]="{type: 'number', max_decimal_places: 0}" style="background:white;" formControlName="qty_estimate" > </mad-input>
            </div>
          </div>
        </div>
        <div 
          class="advanced-pricing-runs-inner" 
          *ngIf="getCompetitiveMatchPrice(data.lims_code) || getCompetitiveMatchRuns(data.lims_code)"
          style="display: flex; gap: 50px; margin-left: auto;background:#e8ecec;padding-left:2em;margin-top:-0.5em;padding-top:1.5em;padding-bottom:0.5em;padding-right:2em;"
        >
          <div *ngIf="getCompetitiveMatchPrice(data.lims_code)">
            <div style="font-size: 14px; color: #333; padding-bottom: 8px;">Competitor price</div>
            <div style="font-size: 18px; font-weight: bold;">${{getCompetitiveMatchPrice(data.lims_code)}}</div>
          </div>
          <div *ngIf="getCompetitiveMatchRuns(data.lims_code)">
            <div style="font-size: 14px; color: #333; padding-bottom: 8px;">Monthly runs</div>
            <div style="font-size: 18px; font-weight: bold;">{{getCompetitiveMatchRuns(data.lims_code)}}</div>
          </div>
        </div>
        <!--</div>-->
      </div>
      <!--<div class="advanced-pricing-middle" >-->
      <div class="advanced-pricing-middle-inner" style="display:flex;background:#eaf5f7;flex-wrap:wrap;padding-top:1em;max-width:1600px;padding-bottom:1em;border-radius:0.5em;margin-top:2em;">
        <div class="advanced-pricing-middle-price-title" style="margin-right:2em;margin-left:2em;font-size:13px">
          <div style="margin-bottom:1em;">Price</div>
          <div style="width:125px;">
            <mad-input style="background:white" [formatter]="{type: 'currency'}" class="price-input" [emptyBlurToFocusStart]="true" formControlName="price"  [value]="data.form.controls.price.value" ></mad-input>
          </div>
        </div>
        <div class="advanced-pricing-title" style="margin-right:2em;margin-bottom:3em;font-size:13px">
          <div style="margin-bottom:1em;">% off list price</div>
          <div style="width:125px;">
            <mad-input style="background:white;text-align:end;" [formatter]="{type: 'percent'}" formControlName="discount" ></mad-input>
          </div>
        </div>
        <div style="flex:1;min-width:500px">
          <div style="color:#aeb1b2;padding-left:200px;text-align:center;font-size:13px">PRICE COMPARISON TO SIMILAR CODES</div>
          <div>
            <div style="display:flex">
              <div style="min-width:200px;font-weight:300;display:flex;align-items:center;justify-content:center;font-size:12px">
                CUSTOMER TEST MIX
              </div>
              <ng-container *ngTemplateOutlet="graph;context:{type:'account'}" ></ng-container>
            </div>
            <div style="display:flex">
              <div style="min-width:200px;font-weight:300;display:flex;align-items:center;justify-content:center;font-size:12px">
                MY TERRITORY
              </div>
              <ng-container *ngTemplateOutlet="graph;context:{type:'territory'}"></ng-container>
            </div>
          </div>
        </div>
        <div style="color:red;font-weight:300;margin-left:2em;margin-top:1em;display:block;width:100%;height:0;transition:height 300ms;overflow:hidden;font-size:13px" [style.height]="(!data?.preapproved) ? '22px' : '0px'">
          Adjusted price below floor and will be reviewed by Sales Support
        </div>
      </div>
      <!--</div>-->
      <div class="advanced-pricing-trg" style="margin-top:1.5em;height:2em;font-size:15px;">
        <div >
          <span *ngIf="+data.qty" >
            Based on a price of <b>{{data?.price | currency}}</b> and current volume of <b>{{(data?.qty || data?.qty) | number}}</b> a month, this will <b>{{data?.currentVolumeTRG >= 0 ? 'increase' : 'decrease'}}</b> TRG by <b>{{data?.currentVolumeTRG | currency}}</b> a month.
          </span>
        </div>
        <div *ngIf="+data.qty && (+data.qty_estimate && +data.qty_estimate !== +data.qty)" style="margin-bottom:1em;"></div>
        <div>
          <span *ngIf="+data.qty_estimate && +data.qty_estimate !== +data.qty">
            With <b>{{+data.qty_estimate > +data.qty ? 'increased ' : (+data.qty_estimate < +data.qty ? 'decreased ' : '')}}</b>utilization of <b>{{data.qty_estimate | number}}</b>, this will <b>{{data?.currentVolumeTRGEstimate >= 0 ? 'increase' : 'decrease'}}</b> TRG by <b>{{data?.currentVolumeTRGEstimate | currency}}</b> a month.
          </span>
        </div>
      </div>
      <div class="advanced-pricing-component-detail" madTypography="header-3" style="font-size:20px;margin-top:3em;display:flex;border-bottom:solid 1px #eee;margin-right:4em;padding-bottom:0.25em;">
        <div style="margin-right:1em;">
          Component detail
        </div>
        <mad-link *ngIf="data.isDetailsExpanded" class="advanced-pricing-component-detail-link" (click)="toggleComponentDetails()">Hide</mad-link>
        <mad-link *ngIf="!data.isDetailsExpanded" class="advanced-pricing-component-detail-link" (click)="toggleComponentDetails()">Show</mad-link>
      </div>
      <mad-spring class="advanced-pricing-component-detail-spring" direction="vertical" [visible]="data.isDetailsExpanded" [skipInitialTransition]="true" >
        <div class="advanced-pricing-component-detail-spring-header" style="display:flex;white-space:nowrap;max-width:75%;margin-top:1.5em;margin-bottom:1.5em;color:#888;font-weight:300;">
          <div class="advanced-pricing-component-detail-spring-first-column"  style="text-overflow:ellipsis;overflow:hidden;margin-right:4em;font-size:15px;width:450px;"></div>
          <div class="advanced-pricing-component-detail-spring-column" style="width:100px;margin-right:1em;display:flex;align-items:flex-end;">Current&nbsp;price</div>
          <div class="advanced-pricing-component-detail-spring-column" style="width:100px;margin-right:1em;display:flex;align-items:flex-end;">List&nbsp;price</div>
          <div class="advanced-pricing-component-detail-spring-last-column" style="width:100px;margin-right:1em;">
            <div>Quantity&nbsp;run&nbsp;standalone</div>
            <div style="font-size:.85em;">(last&nbsp;12&nbsp;months)</div>
          </div>
        </div>
        <div *ngFor="let child of data.children" class="advanced-pricing-component-detail-spring-data" style="display:flex;font-size:15px;white-space:nowrap;align-items:center;max-width:75%;margin-bottom:1.5em;">
          <div class="advanced-pricing-component-detail-spring-first-column" style="text-overflow:ellipsis;overflow:hidden;margin-right:4em;width:450px;color:#888">{{child.lims_code}} - {{child.lims_name}}</div>
          <div class="advanced-pricing-component-detail-spring-column" style="width:100px;position:relative;margin-right:1em;font-weight:bold;font-size:16px;color:#4a4a4a">
            {{formatCurrency(componentPrices?.LYNXX_US[child.lims_code]?.net)}}
            <mad-icon style="position:absolute;left:0;right:0;bottom:0;background:white;top:0" *ngIf="componentPrices?.LYNXX_US[child.lims_code] === true" [loading]="true" loadingIcon="fa-spin fa-spinner"></mad-icon>
          </div>
          <div class="advanced-pricing-component-detail-spring-column" style="width:100px;position:relative;margin-right:1em;font-weight:bold;font-size:16px;color:#4a4a4a">
            {{formatCurrency(componentPrices?.LYNXX_US[child.lims_code]?.list)}}
            <mad-icon style="position:absolute;left:0;right:0;bottom:0;background:white;top:0" *ngIf="componentPrices?.LYNXX_US[child.lims_code] === true" [loading]="true" loadingIcon="fa-spin fa-spinner"></mad-icon>
          </div>
          <div class="advanced-pricing-component-detail-spring-last-column" style="width:100px;position:relative;margin-right:1em;font-weight:bold;font-size:16px;color:#4a4a4a">
            {{formatNumber(componentPrices?.LYNXX_US[child.lims_code]?.qty)}}
            <mad-icon style="position:absolute;left:0;right:0;bottom:0;background:white;top:0" *ngIf="componentPrices?.LYNXX_US[child.lims_code] === true" [loading]="true" loadingIcon="fa-spin fa-spinner"></mad-icon>
          </div>
        </div>
      </mad-spring>
    </div>
  </div>

  <ng-template #graph let-type="type">
    <div style="width:calc(100% - 200px - 80px);z-index:0;flex:1;position:relative;margin-right:100px;height:60px;">
      <div class="bar" style="background:#abb0b1;z-index:0;height:5px;width:100%;position:absolute;top:50%;left:0;transform:translateY(-50%);"></div>
      <div class="range" style="background:#69c744;z-index:0;position:absolute;height:10px;top:50%;transform:translateY(-50%);left:25%;right:25%;"></div>
      <div #marker class="marker" style="background:#666666;z-index:0;height:30px;width:30px;transform:translateX(-50%)translateY(-50%)scaleX(0.7)rotate(45deg);margin-top:-12px;-webkit-mask:linear-gradient(-45deg, black 50%, transparent 50%);position:absolute;top:50%;left:0;" [style.transition]="_initializing ? '' : 'left 500ms'" [style.left]="getMarkerPosition(type, marker)"></div>
      <div class="high-text" style="position:absolute;z-index:0;top:50%;transform:translateY(-50%);left:100%;margin-left:15px;">High</div>
    </div>
  </ng-template>
