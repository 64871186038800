import { Injectable } from '@angular/core';

const BULK_UPLOAD_DATA = 'bulk_upload_data';

@Injectable()
export class CompetitivePricingService {

  getLocalStorageSession() {
    const local = localStorage.getItem(BULK_UPLOAD_DATA);
    return local ? JSON.parse(local) : null;
  }

  removeLocalStorageSession() {
    localStorage.removeItem(BULK_UPLOAD_DATA);
  }

  setLocalStorageSession(data) {
    localStorage.setItem(BULK_UPLOAD_DATA, JSON.stringify(data));
  }
}
