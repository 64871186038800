import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { formatNumber, formatCurrency } from '@angular/common';
import { IPriceData } from '@irl_aws/shared/lib/price';
import { ISelectedPanel } from '../../states/session/session-panels';
import { PriceService } from '../../services/price/price.service';
import { PanelService } from '@irl_app/services/panel/panel.service';

@Component({
    selector: 'mad-selected-panels-details',
    templateUrl: './selected-panels-details.component.html',
    styleUrls: ['./selected-panels-details.component.scss']
})
export class SelectedPanelsDetailsComponent implements OnChanges {
    @Input() data: ISelectedPanel;

    @Input() sapId: string;

    writing = false;

    hasComponentDetails: boolean;

    componentPrices: { LYNXX_US: { [key: string]: IPriceData | boolean } } = { LYNXX_US: {} };

    constructor(
        public priceService: PriceService,
        public panelService: PanelService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue !== changes.data.previousValue) {
            if (this.data.children && this.data.children.length) {
                if (this.data.children.length === 1 && this.data.children[0].lims_code === this.data.lims_code) {
                    this.hasComponentDetails = false;
                } else {
                    this.hasComponentDetails = true;
                }
            } else {
                this.hasComponentDetails = false;
            }
        }
    }

    getRanges() {
        const data = this.data;
        const calc = data.calc;
        const a_c = +calc.a_c;
        const t_c = +calc.t_c;
        const t_σ = +calc.t_σ;
        const ranges = {
            a_c,
            a_o_l: a_c - t_σ,
            a_i_l: a_c - t_σ / 2,
            a_i_m: a_c,
            a_i_h: a_c + t_σ / 2,
            a_o_h: a_c + t_σ,
            t_c,
            t_σ,
            t_o_l: t_c - t_σ,
            t_i_l: t_c - t_σ / 2,
            t_i_m: t_c,
            t_i_h: t_c + t_σ / 2,
            t_o_h: t_c + t_σ
        };
        return ranges;
    }

    getMarkerPosition(type: 'account' | 'territory'): string {
        const ranges = this.getRanges();

        let left: number;
        if (type === 'account') {
            const { a_o_h, a_o_l } = ranges;
            const discount = +(this.data.form.controls.discount.value || 0) / 100;
            const total = a_o_h - a_o_l;
            left = 100 - Math.max(Math.min((discount - a_o_l) / total * 100, 100), 0);
        } else {
            const { t_o_h, t_o_l } = ranges;
            const total = t_o_h - t_o_l;
            const discount = +(this.data.form.controls.discount.value || 0) / 100;
            left = 100 - Math.max(Math.min((discount - t_o_l) / total * 100, 100), 0);
        }

        if (!left || Number.isNaN(left)) {
            left = 0;
        }

        return `${left.toFixed(2)}%`;
    }

    async toggleComponentDetails(): Promise<void> {
        this.data.isDetailsExpanded = !this.data.isDetailsExpanded;
        if (this.data.isDetailsExpanded) {
            const children = this.data.children;
            const prices = this.componentPrices;

            for (const child of children) {
                if (!prices.LYNXX_US[child.lims_code]) {
                    prices.LYNXX_US[child.lims_code] = true;
                }
            }

            const data = await this.priceService.priceComponents({ session: this.data.session, sapId: this.sapId, panel: this.data.id });
            const componentPrices = this.componentPrices;

            if (data) {
                for (const item of data) {
                    componentPrices.LYNXX_US[item.lims_code] = item;
                }
            } else {
                for (const child of children) {
                    if (!prices.LYNXX_US[child.lims_code]) {
                        prices.LYNXX_US[child.lims_code] = undefined;
                    }
                }
            }
        }
    }

    formatNumber(data: number | string): string {
        if (!(+data)) {
            return '-';
        }
        return formatNumber(+data, 'en-US');
    }

    formatCurrency(data: number | string): string {
        if (typeof data !== 'number') {
            return '-';
        }
        return formatCurrency(+data, 'en-US', '$');
    }

    onClose(): void {
        this.data.expanded = false;
        this.data.isDetailsExpanded = false;
        setTimeout(() => {
            this.data.isExpanded = this.data.expanded;
        });
    }

    getCompetitiveMatchPrice(lims_code: string): string {
        const match = this.panelService.getMatch(lims_code);
        return match?.match_price.toFixed(2);
    }
    
    getCompetitiveMatchRuns(lims_code: string): string {
        const match = this.panelService.getMatch(lims_code);
        return match?.match_quantity;
    }
}
