import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { SearchComponent } from './search.component';
import { AuthState } from '../../states/auth/auth.state';
import { AccountState } from '../../states/account/account.state';
import { LIMSService } from '../../services/lims/lims.service';
import { UserService } from '../../services/user/user.service';
import { AccountService } from '../../services/account/account.service';
import { SessionService } from '../../services/session/session.service';
import { SearchService } from '@irl_app/services/search/search.service';
import { CompetitivePricingService } from '@irl_app/services/competitive-pricing/competitive-pricing.service';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ComponentsModule
    ],
    providers: [
        AuthState,
        AccountState,
        AccountService,
        UserService,
        SessionService,
        LIMSService,
        SearchService,
        CompetitivePricingService,
    ],
    declarations: [
        SearchComponent
    ],
    exports: [
        SearchComponent
    ],
    bootstrap: [SearchComponent]
})
export class SearchModule { }
