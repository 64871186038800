import { Injectable } from '@angular/core';
import { SearchPanel } from '@irl_app/models/search-panel.model';

@Injectable()
export class SearchService {

  archiveTest(test: SearchPanel): void {
    localStorage.setItem(test.match_code, JSON.stringify(test));
  }
  
  restoreTest(match_code: string): void {
    localStorage.removeItem(match_code);
  }

  checkArchive(match_code: string): boolean {
    return localStorage.getItem(match_code) !== null;
  }
}
